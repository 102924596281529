import Navbar from "./Navbar";

const NavbarParent = ({page}) => {
    return ( 
        <>
            {/* Home */}
            {page === "Home" && <Navbar imgBanner={"/assets/1289503.jpg"} heading={"THE ESSENSE OF BEAUTY "} title1={"Lorem ipsum dolor sit amet. Veritatis distinctio commodi nobis praesentium cum explicabo debitis eum minima magni voluptates?"} />}
            
            {/* Reviews */}
            {page === "Reviews" && <Navbar imgBanner={"/assets/1291838.jpg"} heading={"THE HOME OF HEALTH "} title1={"Lorem ipsum dolor sit amet consectetur ipsum dolor sit amet adipisicing elit. Nesciunt, corporis!"} />}

            {/* Service */}
            {page === "Service" && <Navbar imgBanner={"/assets/1291653.jpg"} heading={" SPA & BEAUTY CENTER "} title1={"Lorem ipsum dolor sit amet adipisicing elit. Accusantium natus, quia id quibusdam inventore labore sunt suscipit iste maxime omnis saepe cum amet! Architecto, voluptatem repellendus."} />}

            {/* Gallery */}
            {page === "Gallery" && <Navbar imgBanner={"/assets/1291654.jpg"} heading={"DISCOVER YOUR OASIS "} title1={"Lorem ipsum dolor sit amet consectetur adipisicing elit. Ut iusto laborum fuga natus quasi! Nemo aliquid delectus distinctio labore tempore?"} />}

            {/* Book */}
            {page === "Book" && <Navbar imgBanner={"/assets/1020186.jpg"} heading={"FOR BODY AND MIND"} title1={"Lorem ipsum dolor sit amet, consectetur adipisicing elit. Perspiciatis tempore cum est dignissimos at quidem minus dicta optio qui fugiat."} />}

            {/* NotFound */}
            {page === "NotFound" && <Navbar imgBanner={"/assets/NotFound.jpg"} heading={""} title1={""} />}

        </>
     );
}
 
export default NavbarParent;