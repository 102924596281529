import React, { useState, useEffect } from 'react'
import BookConfirmation from "./book-confirmation";
import BookExperience from "./book-experience";
import BookPersonalInfo from './book-personalInfo';
import { useNavigate } from 'react-router-dom';

function Form() {

    const [page, setPage] = useState(0);
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        one_visitingDate: "",
        one_numberOfPeople: "",
        one_fullName: "",
        one_phoneNumber: "",
        one_email: "",
        one_postalCode: "",
        two_serviceTypeId: "0",
        serviceType: "",
        two_estheticianId: "0",
        esthetician: "",
        two_selectedTime: "",
        isCouponApplied : false,
        isInvalidCoupon: "",
        couponNumber: "",
        servicePrice: 150,
        three_creditCardName: "",
        three_cardNumber: "",
        three_expiryMonth: "",
        three_expiryYear: "",
        three_securityCode: "",
        sameAsBilling: false,
        three_billingName: "",
        three_billingPhone: "",
        isAllowedToText: false,
        three_billingAddress: "",
        three_billingPostalCode: "",
        three_billingCountry: "",
        three_billingProvince: "",
        three_billingCity: "",
        isSubscribed: false
    });

    useEffect(() => {
        if (formData.sameAsBilling) {
        // alert('true');
            setFormData(prevData => ({
                ...prevData,
                three_billingName: prevData.one_fullName,
                three_billingPhone: prevData.one_phoneNumber,
                three_billingPostalCode: prevData.one_postalCode
            }))
        } else {
        // alert('false');
        setFormData(prevData => ({
                ...prevData,
                three_billingName: "",
                three_billingPhone: "",
                three_billingPostalCode: ""

            }))
        }
    }, [formData.sameAsBilling])

    const FormTitles = ["Personal Information", "Book Experience", "Confirmation"];
    const prevHide = page === 0; // && "remove-button";
    const nextHide = page === FormTitles.length - 1; //&& "remove-button";
    const submitHide = page !== FormTitles.length - 1 //&& "remove-button";

    const canNextPersonalInfo = Object.keys(formData)
        .filter(key => key.startsWith('one_'))
        .map(key => formData[key])
        .every(Boolean);

    const canNextExperience = Object.keys(formData)
    .filter(key => key.startsWith('two_'))
    .map(key => formData[key])
    .every(Boolean);

    const canPay =  Object.keys(formData)
    .filter(key => key.startsWith('three_'))
    .map(key => formData[key])
    .every(Boolean);

    const disableNext = (page === 0 && !canNextPersonalInfo)
         || (page === 1 && !canNextExperience);

    const disablePay = (page === 2 && !canPay);

    const PageDisplay = () => {
        if(page === 0){
            return <BookPersonalInfo formData={formData} setFormData={setFormData} />
        }
        else if(page === 1) {
            return <BookExperience formData={formData} setFormData={setFormData} />
        }
        else {
            return <BookConfirmation formData={formData} setFormData={setFormData} />
        }
      }  
      
    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(JSON.stringify(formData));
        
        const paymentDetails = {
            "cardNumber" : formData.three_cardNumber,
            "cardName": formData.three_creditCardName,
            "cardExpiryMonth": formData.three_expiryMonth,
            "cardExpiryYear": formData.three_expiryYear,
            "cardSecurityCode": formData.three_securityCode
        };
        const billingDetails= {
            "billingName": formData.three_billingName,
            "billingPhoneNumber": formData.three_billingPhone,
            "billingAddress": formData.three_billingAddress,
            "billingPostalCode": formData.three_billingPostalCode,
            "billingCountry": formData.three_billingCountry,
            "billingProvince": formData.three_billingProvince,
            "billingCity": formData.three_billingCity
        }
        const appointment = {
            "visitingDate": formData.one_visitingDate,
            "numberOfPeople": formData.one_numberOfPeople, 
            "fullName": formData.one_fullName, 
            "phoneNumber": formData.one_phoneNumber, 
            "email": formData.one_email,
            "postalCode": formData.one_postalCode,
            "serviceType": formData.serviceType,
            "servicePrice": formData.servicePrice,
            "esthetician": formData.esthetician,
            "couponNumber": formData.couponNumber, 
            "sameAsBilling": formData.sameAsBilling,
            "isSubscribed": formData.isSubscribed,           
            paymentDetails,
            billingDetails
            };

        console.log(appointment);
        const requestOptions = {
            method: 'POST',
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(appointment)
        }
        fetch('/api/create/', requestOptions)
        .then(() => {
            console.log('New appointment added');
            navigate('/');
        })
        .catch(err => {
            // auto catches network / connection error
            console.log("Here is the error: " + err);
            navigate('/NotFound', {state: err.message})
          })
  }

  return (
    <form className='form' onSubmit={handleSubmit}>
        <div className="progressbar"> 
            <div style={{width: page === 0 ? "33.3%" : page === 1 ? "66.6%" : "100%"}}></div>
        </div>
        {/* <div className="header"><h1>{FormTitles[page]}</h1></div> */}
        <div className="body">{PageDisplay()}</div>
        <div className="container-lg">
            <div className="row">
                <div className="col text-end">
                    { !prevHide && <button 
                        type="button"
                        className="btn btn-outline-secondary"
                        onClick={() => {
                            setPage((currPage) => currPage - 1)
                            }}> Back </button>
                    }
            </div>
                <div className="col">
                    { !nextHide && <button 
                        type="button"
                        disabled={disableNext}
                        className="btn btn-secondary"
                        onClick={() => {                    
                            setPage((currPage) => currPage + 1)
                            }}>Next</button> 
                    }
                    { !submitHide && <button 
                        type="submit"
                        disabled = {disablePay}
                        className="btn btn-secondary">Pay Now</button>
                    }
            </div>
            </div>
        </div>
    </form>
  )
}

export default Form;
