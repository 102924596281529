import React from 'react'

const BookPersonalInfo = ({ formData, setFormData }) => {
    return ( 
        <section id="personalInfo" className="container-lg">
            <div className="row justify-content-center">
                <div className="col-md-4">
                    <div className="input-group input-group-lg">
                        <input required type="date" className="form-control" 
                            value={formData.one_visitingDate} onChange={(e) => {setFormData({...formData, one_visitingDate: e.target.value})}} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
                    </div>                                    
                </div>                    
                <div className="col-md-4">
                    <select required className="form-select form-select-lg mb-3" 
                        value={formData.one_numberOfPeople} onChange={(e) => {setFormData({...formData, one_numberOfPeople: e.target.value})}} aria-label=".form-select-lg example">
                        <option value="">Number of visitors</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                    </select>
                </div>
            </div>
            <div className="row bg-white p-0 my-4">
                <div className="bg-secondary p-3 text-dark bg-opacity-25"><span className="fs-5 fw-semibold">Personal Information</span></div>
                <br/>
                <div className="row p-5">
                    <div className="col-md-6">
                        <div className="input-group input-group-lg my-3">
                            <input required pattern="([A-Z])[\w+.]{1,}" 
                                value={formData.one_fullName} onChange={(e) => {setFormData({...formData, one_fullName: e.target.value})}} placeholder="Full Name *" type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
                        </div>
                        <div className="input-group input-group-lg">
                            <input required placeholder="Email address *" 
                            value={formData.one_email} onChange={(e) =>{setFormData({...formData, one_email: e.target.value})}} type="email" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
                        </div>  
                    </div>
                    <div className="col-md-6">
                        <div className="input-group input-group-lg my-3">
                            <input required placeholder="Phone number *" 
                            value={formData.one_phoneNumber} onChange={(e) => {setFormData({...formData, one_phoneNumber: e.target.value})}} type="number" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
                        </div>
                        <div className="input-group input-group-lg" style={{width: '50%'}} >
                            <input required placeholder="Postal code *" 
                            value={formData.one_postalCode} onChange={(e) => {setFormData({...formData, one_postalCode: e.target.value})}} type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
                        </div>  
                    </div>
                </div>                                
            </div>
            {/* <div className="text-center">
                <button type="submit" className="btn btn-secondary">Submit</button>
            </div>  */}
        </section>
     );
}
 
export default BookPersonalInfo;