import { useRef, useEffect } from "react";
import NavbarParent from './NavbarParent';

const Reviews = () => {
    const refContainer = useRef(null); useEffect(() => { refContainer.current.scrollIntoView({ behavior: "smooth" }); });
    
    return (  
        <>
        <NavbarParent page={"Reviews"} />

        {/* <!-- review list --> */}
        <section id="reviews" className="bg-light" ref={refContainer}>
            <div className="container-lg">
                <br/><br/>
                <div className="text-center">
                    <h1 style={{color: "rgb(188, 147, 69)"}}><i className="bi bi-quote display-1"></i></h1>
                    <h2>Spa Reviews</h2>
                    <p className="lead">Customer reviews about the place...</p>
                </div>

                <div className="row justify-content-center my-5">
                    <div className="col-lg-8">
                        <div className="list-group">
                            <div className="list-group-item py-3">
                                <div className="pb-2">
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                </div>
                                <h5 className="mb-1">A must  experience</h5>
                                <p className="mb-1">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ducimus, in cumque quasi excepturi consequuntur nesciunt!</p>
                                <small>- Review by MA</small>
                            </div>
                            <div className="list-group-item py-3">
                                <div className="pb-2">
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-half"></i>
                                </div>
                                <h5 className="mb-1">A must  experience</h5>
                                <p className="mb-1">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ducimus, in cumque quasi excepturi consequuntur nesciunt!</p>
                                <small>- Review by TM</small>
                            </div>
                            <div className="list-group-item py-3">
                                <div className="pb-2">
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-half"></i>
                                </div>
                                <h5 className="mb-1">A must  experience</h5>
                                <p className="mb-1">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ducimus, in cumque quasi excepturi consequuntur nesciunt!</p>
                                <small>- Review by AT</small>
                            </div>
                            <div className="list-group-item py-3">
                                <div className="pb-2">
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                </div>
                                <h5 className="mb-1">A must  experience</h5>
                                <p className="mb-1">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ducimus, in cumque quasi excepturi consequuntur nesciunt!</p>
                                <small>- Review by RT</small>
                            </div>
                            <div className="list-group-item py-3">
                                <div className="pb-2">
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                    <i className="bi bi-star-fill"></i>
                                </div>
                                <h5 className="mb-1">A must  experience</h5>
                                <p className="mb-1">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ducimus, in cumque quasi excepturi consequuntur nesciunt!</p>
                                <small>- Review by AB</small>
                            </div>
                        </div>
                    </div>
                </div>
                <br/>
            </div>
        </section>
    </>
    );
}
 
export default Reviews;