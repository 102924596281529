import Footer from './Footer';
import Home from './Home';
import Navbar from './Navbar';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Reviews from './Reviews';
import Service from './Service';
import Gallery from './Gallery';
import Book from './book';
import NotFound from './NotFound';

function App() {
  return (
    <Router>
      <div className="App">
        {/* <Navbar/> */}
        {/* <Home/> */}
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/reviews" element={<Reviews />} />
          <Route path="/service" element={<Service />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/book" element={<Book />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer/>
      </div>
    </Router>
  );
}

export default App;
