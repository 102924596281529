import { Link } from "react-router-dom";

const Navbar = ({ imgBanner, heading, title1 }) => {
    // let home = document.querySelector('#home');
    // let scrollToMission = document.querySelector('#home')

    // home.addEventListener('click', () => {
    //     scrollToMission.scrollIntoView({behavior: 'smooth'})
    //     console.log('clicked');
    //   })

    return (         
        <section id="home" className="banner">
            {/* <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/1289503.jpg'} alt="spa banner" />     */}
            <img className="img-fluid" src={process.env.PUBLIC_URL + `${imgBanner}`} alt="spa banner" />    
            <nav className="navbar navbar-expand-md navbar-light sticky-top shadow-sm" 
                style={{backgroundColor: "rgb(255, 255, 255)", width: '100%'}}>
                <div className="container -xxl">
                        <Link className="navbar-brand" to="https://spa.rishitanakum.com/">
                            <span style={{fontSize: '230%', color: 'rgb(188, 161, 111)'}} className="d-inline-block align-top material-symbols-outlined">spa</span>
                            <span className="font-weight text-secondary fs-3 align-bottom">RN Spa</span> 
                        </Link>
            
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                        data-bs-target="#main-nav" aria-controls="main-nav" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
            
                    {/* navabr links  */}
                    <div className="collapse navbar-collapse justify-content-end align-center" id="main-nav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <Link to="/" className="nav-link fs-5 mx-3">Home</Link>
                                </li>
                                <li className="nav-item dropdown">
                                    <a className="nav-link dropdown-toggle fs-5 mx-3" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                    About us
                                    </a>
                                    <ul className="dropdown-menu">
                                    <li><Link className="dropdown-item nav-link" to="/reviews">Reviews</Link></li>
                                    <li><Link className="dropdown-item nav-link" to="/service">Service/Pricing plans</Link></li>
                                    <li><Link className="dropdown-item nav-link" to="/gallery">Gallery</Link></li>
                                    </ul>
                                </li>
                                <li className="nav-item">
                                    <Link to="/book" className="nav-link fs-5 mx-3"><i className="bi bi-calendar-check"></i> Book</Link>
                                </li>
                                <li className="nav-item">
                                    <a href="#contact" className="nav-link fs-5 mx-3">Contact</a>
                                </li>
                                <li className="nav-item">
                                    <a href="#" className="nav-link mt-2 mx-3"><i className="bi bi-bag"></i></a>
                                </li>
                            </ul>
                    </div>
                </div>
            </nav>
            { heading && <div className="content align-items-center bg-white bg-opacity-50">
                <h1 style={{fontSize: '4vw', fontWeight: 'lighter'}}>{heading}</h1>
                <div className="container-lg">
                    <div className="row">
                        <div className="col-md-3"></div>
                        <div className="col-md-6 text-center">
                            <p className="fs-5 d-none d-md-block">{title1}</p>
                            <p className="fs-5">
                                <button type="button" className="btn btn-lg me-3" data-bs-toggle="button"  style={{backgroundColor: "rgb(188, 147, 69)"}}>Purchase</button>
                                <button type="button" className="btn btn-lg" data-bs-toggle="button"  style={{backgroundColor: "rgb(255, 255, 255)"}}>Contact</button>
                            </p>
                        </div>
                        <div className="col-md-3"></div>
                    </div>
                </div>
            </div>
            }
        </section>
     );
}
 
export default Navbar;
