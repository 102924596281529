import Form from './components/Form';
import { useRef, useEffect } from "react";
import NavbarParent from './NavbarParent';

const Book = () => {
    const refContainer = useRef(null); useEffect(() => { refContainer.current.scrollIntoView({ behavior: "smooth" }); });
    
    return ( 
        <>
            <NavbarParent page={"Book"} />

            <section className="bg-light" ref={refContainer}>
                <div className="container-lg">
                    <div className="row justify-content-center">
                        <div className="text-center mt-0">
                            <br/><br/>
                            <div className="display-4">Book your wellness gateway with RN Spa</div>
                            <p className="lead mt-3">A reservation is required.<br/>When would you like to visit us?</p>
                        </div>
                        <div className="mt-4" action="/reservation" method="POST">
                            <div className="row justify-content-center">
                                
                                <Form />
                                {/* personal information */}
                                {/* <BookPersonalInfo /> */}
                                
                                {/* experience */}
                                {/* <BookExperience /> */}

                                {/* confirmation  */}
                                {/* <BookConfirmation /> */}
                                
                            </div>
                            

                        </div>
                    </div>
                    <br/><br/>
                </div>
        </section>
    </>
     );
}
 
export default Book;