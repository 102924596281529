const Footer = () => {
    return ( 
        // <!-- footer -->
        <section id="contact" className="footer-bg">
            <div className="container-lg" style= {{isolation: 'isolate'}}>
                <div className="row justify-content-center">
                    <div className="text-center">
                        <div className="display-6 mt-5">Keep in touch</div>
                        <br/>
                    </div>

                    <div className="col-md-7 col-sm-6 text-center mt-3">
                        <form>
                            <div className="mb-4 input-group">
                                <span className="input-group-text">
                                    <i className="bi bi-person-fill"></i>
                                </span>
                                <input type="text" className="form-control" id="name" placeholder="e.g. Mario" />
                                <span className="input-group-text">
                                    <span className="tt" data-bs-placement="bottom" title="Enter your name.">
                                        <i className="bi bi-question-circle text-muted"></i>
                                    </span>
                                </span>
                            </div>  
                            <div className="mb-4 input-group">
                                <span className="input-group-text">
                                    <i className="bi bi-envelope-fill"></i>
                                </span>
                                <input className="form-control" id="email" type="email" placeholder="e.g. mario@example.con" />
                                <span className="input-group-text">
                                    <span className="tt" data-bs-placement="bottom" title="Enter email address we can reply to.">
                                        <i className="bi bi-question-circle text-muted"></i>
                                    </span>
                                </span>
                            </div>
                                        
                            <textarea id="query" placeholder="Your comment..." className="form-control" style={{height: '140px'}}></textarea>
                            <div className="mb-4 text-center mt-4">
                                <button type="submit" className="btn btn-secondary">Submit</button>
                            </div>
                        </form>
                    </div>
                    <div className="col-md-5 col-sm-6 text-center mt-2">
                        <p>Lorem ipsum dolor sit, amet consectetur Lorem ipsum dolor sit amet consectetur adipisicing elit. Adipisci, nam! adipisicing elit. Quisquam voluptatibus asperiores beatae ut quod debitis, fugiat assumenda dignissimos exercitationem dicta veritatis aspernatur aut obcaecati amet.</p>
                        <br/>
                        <p><i style={{fontSize:'large'}} className="bi bi-telephone-fill"></i>&nbsp; +1 800-000-xxxx</p>
                        <p><i style={{fontSize:'large'}} className="bi bi-envelope-at-fill"></i>&nbsp; info@rnspa.com</p>
                        <p><i style={{fontSize:'large'}} className="bi bi-geo-alt-fill"></i>&nbsp; Lexington Avenue, NY, 0000</p>

                    </div>
                </div>        
            </div>
            <hr/>
            <div className="container-lg" style={{isolation: 'isolate'}}>
                <div className="row">
                    <div className="col-md-6 text-start fs-6 my-2">
                        &copy;2024 RN Spa, All Rights Reserved.
                    </div>
                    <div className="col-md-6 text-end my-2">
                        <span style={{fontSize:'xx-large'}} className="me-2"><a href="#" style={{color: "rgb(188, 147, 69)"}}><i className="bi bi-vimeo"></i></a></span> 
                        <span style={{fontSize:'xx-large'}} className="me-2"><a href="#" style={{color: "rgb(188, 147, 69)"}}><i className="bi bi-instagram"></i></a></span>
                        <span style={{fontSize:'xx-large'}} className="me-2"><a href="#" style={{color: "rgb(188, 147, 69)"}}><i className="bi bi-facebook"></i></a></span>
                        <span style={{fontSize:'xx-large'}} className="me-2"><a href="#" style={{color: "rgb(188, 147, 69)"}}><i className="bi bi-twitter"></i></a></span>
                        <span style={{fontSize:'xx-large'}} className="me-2"><a href="#" style={{color: "rgb(188, 147, 69)"}}><i className="bi bi-pinterest"></i></a></span>
                    </div>
                    <br/><br/>
                </div>
            </div>
        </section>
     );
}
 
export default Footer;