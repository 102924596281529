import { useRef, useEffect } from "react";
import NavbarParent from './NavbarParent';

const Service = () => {
    const refContainer = useRef(null); useEffect(() => { refContainer.current.scrollIntoView({ behavior: "smooth" }); });
   
    return ( 
        
        <>
            <NavbarParent page={"Service"} />

            <section id="service" className="bg-light" ref={refContainer}>
                <div className="container-lg">
                    <div className="row justify-content-center">
                        <div className="text-center">
                            <div className="display-6 mt-5">Pricing Tables</div>
                            <p className="lead-my-4 mt-4 text-muted my-5">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi nobis excepturi culpa repellat distinctio dolore amet. Porro quasi et deserunt reprehenderit sapiente ipsum explicabo corrupti quas officiis. Eum, exercitationem? Eius aperiam modi at deleniti sequi.</p>
                        </div>

                        <div className="row my-5 g-0 align-items-center justify-content-center">
                            <div className="col-8 col-lg-4 col-xl-3">
                            <div className="card border-0">
                                <div className="card-body text-center py-4">
                                <h4 className="card-title">Basic</h4>
                                <p className="lead card-subtitle">Lorem ipsum dolor sit.</p>
                                <p className="display-5 my-4 fw-bold" style={{color: "rgb(188, 147, 69)"}}>$32.99<span className="fs-6 fw-semibold"> /month</span></p>
                                <p className="card-text mx-5 text-muted d-none d-lg-block">Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia, vitae magni! Repellat commodi a fuga corporis saepe dolorum.</p>
                                <button type="button" className="btn fw-semibold" data-bs-toggle="button">Buy Now</button>
                                </div>
                            </div>
                            </div>

                            <div className="col-9 col-lg-4">
                            <div className="card border border-secondary border-2">
                            <div className="card-header text-center fw-semibold fs-6">Most Popular</div>
                                <div className="card-body text-center py-5">
                                <h4 className="card-title">Professional</h4>
                                <p className="lead card-subtitle">Lorem ipsum dolor sit amet.</p>
                                <p className="display-4 my-4 fw-bold" style={{color: "rgb(188, 147, 69)"}}>$55.99<span className="fs-6 fw-semibold"> /month</span></p>
                                <p className="card-text mx-5 text-muted d-none d-lg-block">Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia, vitae magni! Repellat commodi a fuga corporis saepe dolorum.</p>
                                    <button type="button" className="btn fw-semibold" data-bs-toggle="button">Buy Now</button>
                                </div>
                            </div>
                            </div>

                            <div className="col-8 col-lg-4 col-xl-3">
                            <div className="card border-0">
                                <div className="card-body text-center py-4">
                                <h4 className="card-title">Ultimate</h4>
                                <p className="lead card-subtitle">Lorem ipsum dolor sit.</p>
                                <p className="display-5 my-4 fw-bold" style={{color: "rgb(188, 147, 69)"}}>$74.99<span className="fs-6 fw-semibold"> /month</span></p>
                                <p className="card-text mx-5 text-muted d-none d-lg-block">Lorem ipsum dolor sit amet consectetur adipisicing elit. Mollitia, vitae magni! Repellat commodi a fuga corporis saepe dolorum.</p>
                                <button type="button" className="btn fw-semibold" data-bs-toggle="button">Buy Now</button>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
                
            </section>
        </>
     );
}
 
export default Service;