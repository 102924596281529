import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useRef, useEffect } from "react";
import NavbarParent from "./NavbarParent";

const NotFound = () => {
    const location = useLocation();
    const data = location.state;
    const refContainer = useRef(null); useEffect(() => { refContainer.current.scrollIntoView({ behavior: "smooth" }); });
    
    return ( 
        <>
            <NavbarParent page={"NotFound"} />

            <section className="bg-light" ref={refContainer}>
                <div className="container-lg text-start">
                    <br/><br/>
                    <div className="display-6">Oops!!</div>
                    <br/>
                    { data && <p className="lead">{ data }</p> }
                    { !data && <p className="lead">That page cannot be found</p> }
                    <Link to='/'>Back to homepage...</Link>
                    <br/><br/>
                </div>
            </section>
        </>
     );
}
 
export default NotFound;
