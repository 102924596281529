import useFetch from "./useFetch";
import { useRef, useEffect } from "react";
import NavbarParent from "./NavbarParent";

const Gallery = () => {
    const {data: dataset, isPending, error} = useFetch('/api/gallery/');
    const refContainer = useRef(null); useEffect(() => { refContainer.current.scrollIntoView({ behavior: "smooth" }); });

    return ( 
        <>
            <NavbarParent page={"Gallery"} />

            <section className="bg-light" ref={refContainer}>
            <div className="container-lg">
                <div className="row justify-content-center">
                    <div className="text-center">
                        <div className="display-4 mt-5">Gallery grid</div>
                        <p className="lead-my-4 mt-4 text-muted">Accusantium! Dolor tempora similique necessitatibus doloremque soluta voluptate commodi perferendis ullam officia, nemo quisquam, a saepe iusto? Libero quos, quisquam aliquid vitae, molestiae et doloribus est blanditiis beatae laborum modi, impedit cumque nisi? Totam expedita ad sed. Fugit obcaecati delectus accusantium molestias amet qui ut laborum voluptatem totam culpa, ipsam assumenda sit perferendis explicabo. Reiciendis quod ipsam corporis tempore voluptatem. Quisquam deleniti, sunt a harum atque accusamus modi nihil. Ipsam, eligendi vel.</p>
                    </div>

                    <div className="col mt-3">
                        <div className="grid">
                        {dataset && dataset.map((data) => (
                            <div className="img-wrapper" key={data._id}>
                                <img className="zoom blur" src={process.env.PUBLIC_URL + data.imgPath } />
                                <div className="content fade slide-down">
                                        <div className="text-center">
                                            <p className="fw-bolder">{data.title}</p>
                                            <p className="fw-semibold">{data.content}</p>
                                        </div>
                                    </div>
                            </div>
                        ))}
                        <br/><br/>
                        </div>
                    </div>

                </div>
            </div>
        </section>
       </>                 
     );
}
 
export default Gallery;