import React, {useState, useEffect} from 'react'

const BookConfirmation = ({ formData, setFormData }) => {

    const [showMessaage, setShowMessage] = useState(false); 
    const [showMessaageDiscountApplied, setShowMessaageDiscountApplied] = useState(false); 
    
    
    const handleValidateCoupon = (e) => {
        e.preventDefault();
        if(formData.couponNumber === "TEST") { 
            setShowMessage(false);
            setShowMessaageDiscountApplied(true);
            setFormData({...formData, isInvalidCoupon: true});
            setFormData({...formData, servicePrice: (formData.servicePrice - ((formData.servicePrice  * 10 )/100))});
        } else {
            // alert('Invalid coupon!');
            setFormData({...formData, isInvalidCoupon: false});
            setShowMessage(true);
            setShowMessaageDiscountApplied(false);
        }
    }

    const handleValidateSecurityCode = (e) => {
        let { value, min, max } = e.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));

        setFormData({...formData, three_securityCode: value })
    }

    const handleValidateCreditCardNumber = (e) => {
        let { value, min, max } = e.target;
        value = Math.max(Number(min), Math.min(Number(max), Number(value)));

        setFormData({...formData, three_cardNumber: value })
    }

    const isDisable = () => {
        return formData.servicePrice < 150;
      };

      useEffect(() => {
        const isValid = isDisable();
            setFormData({...formData, isCouponApplied: isValid});
    }, [formData.servicePrice]);

    return ( 
        <section id="confirmation" className="container-lg">
            <div className="row bg-white p-0 my-4">
                <div className="bg-secondary p-3 text-dark bg-opacity-25"><span className="fs-5 fw-semibold">Confirmation</span></div>
                <br/>
                <div className="row p-5">
                    <div className="col-md-6">
                        <div className="bg-secondary p-1 text-dark bg-opacity-25"><span className="fs-5 fw-semibold">Payment Information</span></div>
                        {showMessaage && <p className="lead mt-3 bg-danger text-white p-1">Gift certificate is not valid.</p>}
                        {showMessaageDiscountApplied && <p className="lead mt-3 bg-success text-white p-1">Discount is applied.</p>}
                        <div className="input-group input-group-lg my-3">
                            <input placeholder="Certificate or coupon number" type="text" maxLength={5}
                            value={formData.couponNumber} onChange={(e) => {setFormData({...formData, couponNumber: e.target.value })}} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
                            <button className="btn btn-secondary ms-3" disabled={formData.isCouponApplied} onClick={handleValidateCoupon}>Validate</button>
                        </div>
                        <p className="fs-6 fw-bold">Enter your payment Information</p>
                        <div className="fs-6 fw-semibold mb-3">Credit card</div>
                        <div className="input-group input-group-lg my-3">
                            <input required placeholder="Name on the credit card" type="text" maxLength={25}
                            value={formData.three_creditCardName} onChange={(e) => {setFormData({...formData, three_creditCardName: e.target.value })}} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
                        </div>
                        <div className="input-group input-group-lg my-3">
                            <input required placeholder="Credit card number" type='number' className="form-control" min="0000000000000000" max="9999999999999999"
                                value={formData.three_cardNumber} onChange={handleValidateCreditCardNumber} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>                            
                        </div>
                        <div className="row">
                            <div className="col">
                                <div className="input-group input-group-lg my-3">
                                    <select required className="form-select form-select-lg mb-3" 
                                    value={formData.three_expiryMonth} onChange={(e) => {setFormData({...formData, three_expiryMonth: e.target.value })}} aria-label=".form-select-lg example">
                                        <option value="">Month</option>
                                        <option value="1">Jan</option>
                                        <option value="2">Feb</option>
                                        <option value="3">Mar</option>
                                        <option value="4">Apr</option>
                                        <option value="5">May</option>
                                        <option value="5">Jun</option>
                                        <option value="5">Jul</option>
                                        <option value="5">Aug</option>
                                        <option value="5">Sep</option>
                                        <option value="5">Oct</option>
                                        <option value="5">Nov</option>
                                        <option value="12">Dec</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col">
                                <div className="input-group input-group-lg my-3">
                                    <select required className="form-select form-select-lg mb-3" 
                                        value={formData.three_expiryYear} onChange={(e) => {setFormData({...formData, three_expiryYear: e.target.value })}} aria-label=".form-select-lg example">
                                        <option value="">Year</option>
                                        <option value="2024">2024</option>
                                        <option value="2025">2025</option>
                                        <option value="2025">2026</option>
                                </select>
                                </div>
                            </div>
                            <div className="col">
                                <div className="input-group input-group-lg my-3">
                                    <input required placeholder="Security code" type='number' min="000" max="999"
                                    value={formData.three_securityCode} onChange={handleValidateSecurityCode} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
                                </div>
                            </div>

                        </div>

                        <div className="row">
                            <div className="col">
                                <p className="lead fs-6 fw-bold">Billing information</p>
                            </div>
                            <p>
                                <input className="form-check-input" type="checkbox" id="usemyInfo" 
                                    checked={formData.sameAsBilling}
                                    onChange={(e) => {
                                        const value = e.target.type === "checkbox"
                                            ? e.target.checked
                                            : e.target.value
                                        setFormData({...formData, sameAsBilling: value})
                                        }}/> 
                                <label className="form-check-label ms-2" htmlFor="usemyInfo">Use my name for billing information</label>
                            </p>
                            <div className="input-group input-group-lg my-3">
                                <input required placeholder="Full name" type="text" className="form-control" maxLength={25}
                                    disabled={formData.sameAsBilling} value={formData.three_billingName} onChange={(e) => {setFormData({...formData, three_billingName: e.target.value })}} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
                            </div>
                            <div className="input-group input-group-lg my-3">
                                <input required placeholder="(819) 555-5555 / +78 100 1111111" type="number" maxLength={16}
                                    disabled={formData.sameAsBilling} value={formData.three_billingPhone} onChange={(e) => {setFormData({...formData, three_billingPhone: e.target.value })}} className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
                            </div>
                            <p>
                                <input className="form-check-input" type="checkbox" id="allowedToContact" 
                                    checked={formData.isAllowedToText}
                                    onChange={(e) => {
                                        const value = e.target.type === "checkbox"
                                            ? e.target.checked
                                            : e.target.value
                                        setFormData({...formData, isAllowedToText: value})
                                        }} /> 
                                <label className="form-check-label ms-2" htmlFor="allowedToContact">I agree to be contacted by text message.</label>
                            </p>
                            <div className="col-md-8">
                                <div className="input-group input-group-lg my-3">                                                
                                    <input required placeholder="Address" type="text" className="form-control" maxLength={16}
                                        value={formData.three_billingAddress} onChange={(e) => {setFormData({...formData, three_billingAddress: e.target.value })}} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
                                </div>                                                
                            </div>
                            <div className="col">
                                <div className="input-group input-group-lg my-3">                                                
                                    <input required placeholder="Postal code" type="text" className="form-control" maxLength={6}
                                        disabled={formData.sameAsBilling} value={formData.three_billingPostalCode} onChange={(e) => {setFormData({...formData, three_billingPostalCode: e.target.value })}} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
                                </div> 
                            </div>
                            <div className="col-md-6">
                                <div className="input-group input-group-lg my-3">   
                                <input required placeholder="Country" type="text" className="form-control" maxLength={16}
                                        value={formData.three_billingCountry} onChange={(e) => {setFormData({...formData, three_billingCountry: e.target.value })}} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>                                             
                                {/* <select required className="form-select form-select-lg" 
                                    value={formData.three_billingCountry} onChange={(e) => {setFormData({...formData, three_billingCountry: e.target.value })}} aria-label=".form-select-lg example">
                                    <option value="">Country</option>
                                    <option value="CA">Canada</option>
                                </select> */}
                                </div>                                                
                            </div>
                            <div className="col-md-6">
                                <div className="input-group input-group-lg my-3"> 
                                <input required placeholder="State" type="text" className="form-control" maxLength={16}
                                        value={formData.three_billingProvince} onChange={(e) => {setFormData({...formData, three_billingProvince: e.target.value })}} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>                                             
                                                                               
                                {/* <select required className="form-select form-select-lg" 
                                    value={formData.three_billingProvince} onChange={(e) => {setFormData({...formData, three_billingProvince: e.target.value })}} aria-label=".form-select-lg example">
                                    <option value="">Province</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                    <option value="SixPlus">6 + </option>
                                </select> */}
                                </div> 
                            </div>
                            <div className="input-group input-group-lg my-3">
                                <input required placeholder="City" type="text" className="form-control" maxLength={16}
                                value={formData.three_billingCity} onChange={(e) => {setFormData({...formData, three_billingCity: e.target.value })}} aria-label="Sizing example input" aria-describedby="inputGroup-sizing-lg"/>
                            </div>
                            <p className="lead fs-6 text-decoration-underline">By making a reservation, I agree to the terms and conditions of sale and confirm that all visitors are <span className="fw-bold">19 years old or older.</span></p>
                            <p>
                                <input className="form-check-input" type="checkbox" id="wishToSubscribe" 
                                    checked={formData.isSubscribed}
                                    onChange={(e) => {
                                        const value = e.target.type === "checkbox"
                                            ? e.target.checked
                                            : e.target.value
                                        setFormData({...formData, isSubscribed: value})
                                        }} /> I wish to subscribe to the RN Spa | Whitby newsletter for contests, news and special offers.
                            </p>
                        </div>
                        
                    </div>
                    {/* <div className="col-md-1"></div> */}
                    <div className="col-md-6 p-0 m-0">
                        <div className="bg-secondary p-1 text-dark bg-opacity-25"><span className="fs-5 fw-semibold">Reservation Summary</span></div>
                        <div className="container-lg">
                            <div className="row mt-3">
                                <p className="col ms-1 p-0 fw-bold">{formData.fullName}</p> 
                            </div>
                            <div className="row ms-1">
                                <div className="col-md-9 p-1">{formData.serviceType}</div>
                                <div className="col-md-3 p-1 text-end">{`$${formData.servicePrice} CAD`}</div>
                            </div>
                            <div className="row ms-1">
                                <div className="col-md-9 p-1">Guest Total</div>
                                <div className="col-md-3 p-1 text-end">{`$${formData.servicePrice} CAD`}</div>
                            </div>
                            <hr/>
                            <div className="row ms-1 fw-bold">
                                <div className="col-md-9 p-1">Subtotal</div>
                                <div className="col-md-3 p-1 text-end">{`$${formData.servicePrice} CAD`}</div>
                            </div>
                            <div className="row ms-1">
                                <div className="col-md-9 p-1">HST</div>
                                <div className="col-md-3 p-1 text-end">{`$${formData.servicePrice * 0.10} CAD`}</div>
                            </div>
                            <div className="row ms-1 fw-bold">
                                <div className="col-md-9 p-1">Total</div>
                                <div className="col-md-3 p-1 text-end">{`$${formData.servicePrice + (formData.servicePrice * 0.10)} CAD`}</div>
                            </div>

                            <br/><br/>
                            <div className="row ms-1 fw-bold">
                                <div className="col-md-9 p-1">Total to pay</div>
                                <div className="col-md-3 p-1 text-end">{`$${formData.servicePrice + (formData.servicePrice * 0.10)} CAD`}</div>
                            </div>
                        </div>
                        
                    </div>
                </div>                                
            </div>
            
            {/* <div className="text-center">
                <button type="submit" className="btn btn-outline-secondary mx-3">Back</button>
                <button type="submit" className="btn btn-secondary mx-3">Pay Now</button>
            </div> */}
        </section>
     );
}
 
export default BookConfirmation;