import { useState, useEffect } from "react";

const BookExperience = ({ formData, setFormData }) => {

    const [isTimeVisible, setIsTimeVisible] = useState(false);
    const [isEstheticianDisable, setIsEstheticianDisable] = useState(true);

    const handleChangeEsthetician = (e) => {
        // console.log("Service OPERATOR change");
        setFormData({...formData, two_estheticianId: e.target.value, esthetician: e.target.options[e.target.value].text});
        if (e.target.value !== "0") {
            setIsTimeVisible(true);
        } else {
            setIsTimeVisible(false);
            setFormData({...formData, two_estheticianId: "0", esthetician: "0"});
            // setFormData({...formData, two_selectedTime: ""});
        }
    }

    useEffect(() => {
        if(formData.two_serviceTypeId === "0") {
            setFormData({...formData, two_estheticianId: "0", esthetician: "0"});
            setIsEstheticianDisable(true);
            setIsTimeVisible(false);
        } else {
            setIsEstheticianDisable(false);
        }
    }, [formData.two_serviceTypeId]);

    useEffect(() => {
        if(formData.two_serviceTypeId === "0") {
            setFormData({...formData, two_estheticianId: "0", esthetician: "0"});
            setIsEstheticianDisable(true);
            setIsTimeVisible(false);
        } else {
            setIsEstheticianDisable(false);
        }
        if(formData.two_estheticianId === "0") {
            setFormData({...formData, two_selectedTime: ""});
        }
    }, [formData.two_estheticianId]);
    

    useEffect(() => {
        if (formData.two_serviceTypeId !== "0" ) {
            setIsEstheticianDisable(false);
        }
        if (formData.two_estheticianId !=="0") {
            setIsTimeVisible(true);
        }
    }, []);

    return ( 
        <div id="experience" className="container-lg">
            {/* <h1>Service type: {formData.two_serviceTypeId}</h1>
            <h1>Esthetician: {formData.two_estheticianId}</h1>
            <h1>{formData.two_selectedTime}</h1>  */}
            <div className="row bg-white p-0 my-4">
                <div className="bg-secondary p-3 text-dark bg-opacity-25"><span className="fs-5 fw-semibold">Experience</span></div>
                <br/>
                <div className="row p-5">
                    <div className="col-md-6">
                        <div className="input-group input-group-lg my-3">
                            <select required className="form-select form-select-lg mb-3" name="serviceType"
                                value={formData.two_serviceTypeId} onChange={(e) => {setFormData({...formData, two_serviceTypeId: e.target.value, serviceType: e.target.options[e.target.value].text } )}} 
                                aria-label=".form-select-lg example">
                                <option value="0">Select type of service</option>
                                <option value="1">Relaxation Massage</option>
                                <option value="2">Mom-to-be Massage</option>
                                <option value="3">Intensive Massage</option>
                                <option value="4">Urban Detox Face care</option>
                                <option value="5">Aromatherapy Foot Care</option>
                                <option value="6">Himalayan Salt Scrub</option>
                                <option value="7">Face Care For  Men</option>
                            </select>
                        </div>
                        <div className="input-group input-group-lg">
                            <select required className="form-select form-select-lg mb-3" aria-label=".form-select-lg example"
                                disabled={isEstheticianDisable} name="operatorType"
                                value={formData.two_estheticianId} onChange={handleChangeEsthetician}>
                                <option value="0">Select your esthetician</option>
                                <option value="1">Sabrina D</option>
                                <option value="2">Marium H</option>
                                <option value="3">Mike R</option>
                                <option value="4">Bob T</option>
                                <option value="5">Julie F</option>
                            </select>
                        </div>
                        { isTimeVisible && 
                            <div id="selectTime">                                            
                                <p className="my-2 fw-semibold">Choose the time that suits you: </p>
                                <div className="btn-group me-3 my-2" data-toggle="buttons">
                                    <input type="radio" className="btn-check" name="options-base" 
                                        value="9:00" checked={formData.two_selectedTime === "9:00"}
                                        onChange={(e) => { setFormData({...formData, two_selectedTime: e.target.value}) }} 
                                        id="option5" autoComplete="off" />
                                    <label className="btn btn-outline-secondary" htmlFor="option5">9:00</label>
                                </div>
                                <div className="btn-group me-3 my-2" data-toggle="buttons">
                                    <input type="radio" className="btn-check" name="options-base" 
                                        value="10:30" checked={formData.two_selectedTime === "10:30"}
                                        onChange={(e) => { setFormData({...formData, two_selectedTime: e.target.value}) }} 
                                        id="option6" autoComplete="off" />
                                    <label className="btn btn-outline-secondary" htmlFor="option6">10:30</label>
                                </div>
                                <div className="btn-group me-3 my-2" data-toggle="buttons">
                                    <input type="radio" className="btn-check" name="options-base" 
                                        value="12:00" checked={formData.two_selectedTime === "12:00"}
                                        onChange={(e) => { setFormData({...formData, two_selectedTime: e.target.value}) }} 
                                        id="option7" autoComplete="off" />
                                    <label className="btn btn-outline-secondary" htmlFor="option7">12:00</label>
                                </div>
                                <div className="btn-group me-3 my-2" data-toggle="buttons">
                                    <input type="radio" className="btn-check" name="options-base" 
                                        value="1:30" checked={formData.two_selectedTime === "1:30"}
                                        onChange={(e) => { setFormData({...formData, two_selectedTime: e.target.value}) }} 
                                        id="option8" autoComplete="off" />
                                    <label className="btn btn-outline-secondary" htmlFor="option8">1:30</label>                
                                </div>

                            </div>
                        }
                    </div>
                    <div className="col-md-6">
                        <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/massage.avif'} alt="" />
                    </div>
                </div>
            </div>
            {/* <div className="text-center">
                <button type="submit" className="btn btn-outline-secondary mx-3">Cancel</button>
                <button type="submit" className="btn btn-secondary mx-3">Next</button>
            </div> */}
        </div>
     );
}
 
export default BookExperience;