import { useRef, useEffect } from "react";
import NavbarParent from './NavbarParent';

const Home = () => {
    const refContainer = useRef(null); useEffect(() => { refContainer.current.scrollIntoView({ behavior: "smooth" }); });
    
    return ( 
        <>
        <section ref={refContainer}> 
            <NavbarParent page={"Home"} />
        </section>

        {/* First body section */}
        <div className="BodyContent">
            <section>
                <div className="container-lg my-5">
                    <div className="row justify-content-center">
                        <div className="text-center">
                            <div className="display-6">Where elegance dwells</div>
                            <div className="display-4">Lorem, ipsum dolor.</div>
                            <p className="lead-my-4 mt-4 text-muted">Lorem ipsum dolor, sit repudiandae architecto labore corporis inventore accusantium! Dolor tempora similique necessitatibus doloremque soluta voluptate commodi perferendis ullam officia, nemo quisquam, a saepe iusto? Libero quos, quisquam aliquid vitae, molestiae et doloribus est blanditiis beatae laborum modi, impedit cumque nisi? Totam expedita ad sed. Fugit obcaecati delectus accusantium molestias amet qui ut laborum voluptatem totam culpa, ipsam assumenda sit perferendis explicabo. Reiciendis quod ipsam corporis tempore voluptatem. Quisquam deleniti, sunt a harum atque accusamus modi nihil. Ipsam, eligendi vel.</p>
                        </div>

                        <div className="col-md-3 col-sm-6 text-center mt-3">
                            <span style={{fontSize: '330%', color: "rgb(167, 144, 100)"}} className="mb-2 d-inline-block align-top material-symbols-outlined">self_improvement</span>
                            <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Unde molestiae itaque eaque reiciendis consequatur quis quae tempore fugiat neque iure! Qui earum quibusdam veritatis pariatur.</p>
                        </div>
                        <div className="col-md-3 col-sm-6 text-center mt-3">
                            <span style={{fontSize: '330%', color: "rgb(167, 144, 100)"}} className="mb-2 d-inline-block align-top material-symbols-outlined">relax</span>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellendus velit doloremque molestiae consequatur reprehenderit voluptatem eius excepturi ex saepe inventore ut, animi perferendis, ab ad.</p>
                        </div>
                        <div className="col-md-3 col-sm-6 text-center mt-3">
                            <span style={{fontSize: '330%', color: "rgb(167, 144, 100)"}} className="mb-2 d-inline-block align-top material-symbols-outlined">mindfulness</span>
                            <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quisquam voluptatibus asperiores beatae ut quod debitis, fugiat assumenda dignissimos exercitationem dicta veritatis aspernatur aut obcaecati amet.</p>
                        </div>
                        <div className="col-md-3 col-sm-6 text-center mt-3">
                            <span style={{fontSize: '330%', color: "rgb(167, 144, 100)"}} className="mb-2 d-inline-block align-top material-symbols-outlined">sentiment_calm</span>
                            <p>Lorem ipsum, dolor sit amet consectetur adipisicing elit. At ea aut voluptatum nesciunt explicabo impedit cupiditate velit voluptatem officiis quos porro numquam, quia deleniti saepe.</p>
                        </div>
                    </div>
                </div>
            </section>

            {/* Different therapy section */}
            <section className="bg-light">
                <div className="container-lg my-5">
                    <div className="row justify-content-center">
                        <div className="text-center">
                            <div className="display-6 mt-5">Enjoy the differences</div>
                            <p className="lead text-muted mt-3">Lorem ipsum dolor sit amet consectetur, adipisicing elit. Modi nobis excepturi culpa repellat distinctio dolore amet. Porro quasi et deserunt reprehenderit sapiente ipsum explicabo corrupti quas officiis. Eum, exercitationem? Eius aperiam modi at deleniti sequi.</p>
                        </div>

                        <div className="col-md-4 col-sm-6 text-center mt-3">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/aromatherapy-definition.jpg'} alt="" />
                            <p className="mt-3">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Repellendus velit doloremque molestiae consequatur reprehenderit voluptatem eius excepturi ex saepe inventore ut, animi perferendis, ab ad.</p>
                        </div>
                        <div className="col-md-4 col-sm-6 text-center mt-3">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/aloevera.avif'} alt="" />
                            <p className="mt-3">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Quisquam voluptatibus asperiores beatae ut quod debitis, fugiat assumenda dignissimos exercitationem dicta veritatis aspernatur aut obcaecati amet.</p>
                        </div>
                        <div className="col-md-4 col-sm-6 text-center mt-3">
                        {/* src={process.env.PUBLIC_URL + '/assets/1289503.jpg'} */}
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/facial.jpg'} alt="" />
                            <p className="mt-3">Lorem ipsum, dolor sit amet consectetur adipisicing elit. At ea aut voluptatum nesciunt explicabo impedit cupiditate velit voluptatem officiis quos porro numquam, quia deleniti saepe.</p>
                        </div>
                        <div className="mt-5"></div>
                    </div>
                </div>
            </section>

            {/* <!-- Accordian section --> */}
            <section>
                <div className="container-lg">
                    <div className="row justify-content-center mt-5">
                        <div className="text-center mt-3"></div>
                        <div className="col-md-6 text-center mt-3">
                            <div className="accordion" id="accordionExample">
                                <div className="accordion-item border border-0 mb-4">
                                <h2 className="accordion-header">
                                    <button style= {{backgroundColor: "rgb(241, 234, 219)", border: 'thin rgb(188, 161, 111)'}} className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    WHAT WE DO
                                    </button>
                                </h2>
                                <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>Lorem ipsum dolor sit amet.</strong> Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquam, harum est reprehenderit non rem explicabo dolor praesentium blanditiis? Inventore praesentium necessitatibus officiis nisi ad vitae.
                                    </div>
                                </div>
                                </div>
                                <div className="accordion-item border border-0 mb-4">
                                <h2 className="accordion-header">
                                    <button style={{backgroundColor: "rgb(241, 234, 219)", border: 'thin rgb(188, 161, 111)'}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    OUR RESORT
                                    </button>
                                </h2>
                                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>Lorem ipsum dolor sit amet consectetur adipisicing.</strong> Lorem ipsum, dolor sit amet consectetur adipisicing elit. Exercitationem numquam saepe nisi soluta dolores quia mollitia iure modi laudantium sunt minima, pariatur cum voluptatum. Voluptas, culpa voluptate incidunt quisquam expedita hic! Neque praesentium molestias quod.
                                    </div>
                                </div>
                                </div>
                                <div className="accordion-item border border-0 mb-4">
                                    <h2 className="accordion-header">
                                    <button style={{backgroundColor: "rgb(241, 234, 219)", border: 'thin rgb(188, 161, 111)'}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseTwo">
                                        YOUGA STUDIO
                                    </button>
                                    </h2>
                                    <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>Lorem ipsum dolor sit amet.</strong> Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam assumenda illum ex pariatur ad totam molestiae aliquid dicta, nemo consequatur!
                                    </div>
                                    </div>
                                </div>
                                <div className="accordion-item border border-0 mb-4">
                                <h2 className="accordion-header">
                                    <button style={{backgroundColor: "rgb(241, 234, 219)", border: 'thin rgb(188, 161, 111)'}} className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseThree">
                                    BLOOMING HEALTH
                                    </button>
                                </h2>
                                <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-body">
                                        <strong>Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus, amet.</strong> Lorem, ipsum dolor sit amet consectetur adipisicing elit. Sint fugiat aliquid architecto nisi unde consectetur repellendus corrupti harum temporibus voluptas! Tempora, et tenetur ducimus quam iure animi sunt ab dolor!
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 text-center mt-3">
                            <img className="img-fluid" src={process.env.PUBLIC_URL + '/assets/massage.avif'} alt="" />
                        </div>
                        <div className="text-center mt-4"></div>
                    </div>
                </div>
            </section>


        </div>

    
        </>
     );
}
 
export default Home;